import { Box, Button, Paper, Typography } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Container from '@mui/material/Container'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

export const PreUnsubBackdrop = ({ isShowBackdrop, handleYesClick, handleNoClick }: { isShowBackdrop: any; handleYesClick: any; handleNoClick: any }) => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        overflowY: 'scroll'
      }}
      open={isShowBackdrop}
    >
      <Container maxWidth="sm">
        <Grid2 sm={3}>
          <Paper
            sx={{
              p: 3,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              rowGap: 4
            }}
          >
            <Typography fontWeight="bold" variant="h6" textAlign="center">
              Unsubscribe
            </Typography>
            <Typography variant="body2">Instead of unsubscribing, you can sell or transfer the remaining time left on your plan to benefit another fellow candidate. Find out more about transferring subscription?</Typography>
            <Box display="flex" justifyContent="space-around">
              <Button
                variant="contained"
                color="inherit"
                onClick={handleYesClick}
                sx={{
                  px: 5,
                  borderRadius: 5,
                  color: 'white'
                }}
              >
                Yes
              </Button>
              <Button sx={{ color: 'black' }} variant="text" onClick={handleNoClick}>
                No
              </Button>
            </Box>
          </Paper>
        </Grid2>
      </Container>
    </Backdrop>
  )
}
