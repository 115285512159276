import { useEffect, useRef } from "react"
import { Backdrop, Box, Button, Container, FormControl, FormControlLabel, Input, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material"

type Props = { isShowJumpstartBackdrop: any; startUnsubFlow: any; handleBackToHomeClick: any; jumpstartCareerResponse: any; setJumpstartCareerResponse: any; mobileNo: any; setMobileNo: any; invaidPhoneNoErrMsg: string; setInvalidPhoneNoErrMsg: any }

const JumpstartCareerBackdrop = ({ isShowJumpstartBackdrop, startUnsubFlow, handleBackToHomeClick, jumpstartCareerResponse, setJumpstartCareerResponse, mobileNo, setMobileNo, invaidPhoneNoErrMsg, setInvalidPhoneNoErrMsg }: Props) => {
  const mobileNoRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setJumpstartCareerResponse("yes")
    mobileNoRef.current?.focus()
  }, [])

  return (
    <Box>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          overflowY: "scroll",
        }}
        open={isShowJumpstartBackdrop}
      >
        <Paper
          sx={{
            maxWidth: {
              xs: "25rem",
              md: "40rem",
            },
            minWidth: {
              xs: "25rem",
              md: "40rem",
            },
            backgroundColor: "white",
            borderRadius: "20px",
            mt: 5,
          }}
        >
          <Container
            sx={{
              py: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              rowGap: 2,
            }}
          >
            <Typography variant="body1">Stepping into your Real Estate career can be daunting.. would you like us to connect you with a mentor from one of Singapore&lsquo;s largest estate agencies to jumpstart your career?</Typography>

            <img src="/jumpstart_career.png" alt="jumpstart_career" style={{ width: "100%", height: "275px" }} />

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <FormControl>
                <RadioGroup
                  onChange={(e) => {
                    setInvalidPhoneNoErrMsg("")
                    setJumpstartCareerResponse(e.target.value)
                  }}
                  value={jumpstartCareerResponse}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes! I&lsquo;d like to explore jump-starting my realtor career with a good mentor!" />
                  {jumpstartCareerResponse === "yes" && (
                    <Box display="flex" justifyContent="center">
                      <TextField
                        helperText={invaidPhoneNoErrMsg}
                        error={invaidPhoneNoErrMsg ? true : false}
                        placeholder="99990001"
                        variant="outlined"
                        label="Phone Number"
                        value={mobileNo}
                        sx={{ backgroundColor: "white", my: 3, width: "250px" }}
                        onChange={(e) => {
                          setInvalidPhoneNoErrMsg("")
                          setMobileNo(e.target.value)
                        }}
                        inputRef={mobileNoRef}
                        autoComplete="false"
                      />
                    </Box>
                  )}
                  <FormControlLabel value="no" control={<Radio />} label="No thanks, I already have a mentor" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Button
              variant="contained"
              role="button"
              sx={{
                px: 5,
                borderRadius: 5,
                width: "15rem",
              }}
              onClick={() => handleBackToHomeClick()}
            >
              Back to home
            </Button>
            <Button variant="text" onClick={() => startUnsubFlow()} color="error">
              Continue to unsubscribe
            </Button>
          </Container>
        </Paper>
      </Backdrop>
    </Box>
  )
}
export default JumpstartCareerBackdrop
