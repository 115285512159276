import { useEffect, useState } from 'react'
import { Box, Container, Typography } from '@mui/material'

import { MainNav } from '../components/Layout/MainNav'
import { UserTable } from '../components/UserTable'
import { useAxiosPrivate } from '../hooks/useAxiosPrivate'

export interface IUser {
  email: string
  roles: string[]
}

export const User = () => {
  const axiosPrivate = useAxiosPrivate()
  const [users, setUsers] = useState<IUser[]>([])

  useEffect(() => {
    const getUsers = async () => {
      // require auth
      try {
        const url = '/users'
        const { data } = await axiosPrivate.get(url)

        if (Array.isArray(data?.users)) {
          setUsers(data.users)
        }
      } catch (err: any) {
        // console.error(err.message)
      }
    }

    getUsers()
  }, [])

  return (
    <Box>
      <MainNav />
      <Container sx={{ mt: 5 }}>
        <Typography variant="h4" sx={{ color: 'rgb(42, 124, 111)', mb: 2 }}>
          Users ({users.length})
        </Typography>
        <UserTable users={users} />
      </Container>
    </Box>
  )
}
