import { Backdrop, Box, Button, Container, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Typography } from '@mui/material'

type Props = {
  isShowSurveyBackdrop: boolean
  setIsShowSurveyBackdrop: any
  surveyResponse: string
  setSurveyResponse: any
  handleSubmitSurvey: any
}
const SurveyBackdrop = ({ isShowSurveyBackdrop, setIsShowSurveyBackdrop, surveyResponse, setSurveyResponse, handleSubmitSurvey }: Props) => {
  return (
    <Box>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          overflowY: 'scroll'
        }}
        open={isShowSurveyBackdrop}
      >
        <Paper
          sx={{
            maxWidth: '25rem',
            minWidth: '2rem',
            backgroundColor: 'white',
            borderRadius: '20px',
            mt: 5
          }}
        >
          <Container
            sx={{
              py: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              rowGap: 2
            }}
          >
            <Typography variant="h6" sx={{ textAlign: 'center' }}>
              Have you passed your RES Exam?
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
              <FormControl>
                <RadioGroup onChange={(e) => setSurveyResponse(e.target.value)} value={surveyResponse}>
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel value="waiting_for_results" control={<Radio />} label="Waiting for results but I'm hopeful!" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Button
              variant="contained"
              role="button"
              sx={{
                px: 5,
                borderRadius: 5,
                width: '15rem'
              }}
              onClick={() => handleSubmitSurvey()}
            >
              Submit
            </Button>
          </Container>
        </Paper>
      </Backdrop>
    </Box>
  )
}
export default SurveyBackdrop
