import Box from '@mui/material/Box'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import moment from 'moment'

import { IUser } from '../pages/User'

export const UserTable = ({ users }: { users: IUser[] }) => {
  const columns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Email',
      width: 250
    },
    {
      field: 'roles',
      headerName: 'Roles',
      valueFormatter: ({ value }) => {
        if (Array.isArray(value)) {
          return value.join(', ')
        }
        return ''
      },
      width: 250
    },
    // {
    //   field: 'subscriptions',
    //   headerName: 'No. of payments',
    //   valueFormatter: ({ value }) => {
    //     return value.length
    //   },
    //   width: 250
    // },
    {
      field: 'subscriptions',
      headerName: 'Latest Sub End Date',
      valueFormatter: ({ value }) => {
        const latestObj = value.length ? value[value.length - 1] : null

        if (latestObj) {
          return moment.unix(latestObj.subEndS).format('Do MMM YYYY hh:mm A')
        }

        return 'N/A'
      },
      width: 250
    }
  ]

  return (
    <Box sx={{ height: 800, width: '100%' }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={users}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[10, 20, 30]}
      />
    </Box>
  )
}
